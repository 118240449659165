<template>
  <div class="row">
    <div
      class="col-md-12 text-right mb-2"
      v-if="$store.getters.can('cs.tarifarios.createTarifasAdicionales')"
    >
      <button class="btn btn-primary" @click="getFormTarifaAdd(1)">
        <i class="fas fa-plus"></i>
      </button>
    </div>

    <div class="col-md-12">
      <table
        class="table table-bordered table-striped table-hover table-sm mb-0"
      >
        <thead class="thead-dark">
          <tr>
            <th class="col-2 text-center">Nombre</th>
            <th class="col-2 text-center" v-if="flag_form_detalle == 1">
              Fecha Inicial
            </th>
            <th class="col-2 text-center" v-else>Vigencia</th>
            <th class="col-1 text-center">Valor</th>
            <th class="col-1 text-center">Tipo</th>
            <th class="col-1 text-center">Estado</th>
            <th class="col-1 text-center">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="flag_form_detalle == 1">
            <td>
              <input
                v-model="form_tarifa.nombre"
                type="text"
                class="form-control form-control-sm"
                :class="
                  $v.form_tarifa.nombre.$invalid ? 'is-invalid' : 'is-valid'
                "
              />
            </td>
            <td class="text-center">
              <input
                type="date"
                class="form-control form-control-sm"
                v-model="form_tarifa.fecha_ini"
                :class="
                  $v.form_tarifa.fecha_ini.$invalid ? 'is-invalid' : 'is-valid'
                "
              />
            </td>

            <td class="text-center">
              <input
                type="number"
                class="form-control form-control-sm"
                v-model="form_tarifa.valor"
                :class="
                  $v.form_tarifa.valor.$invalid ? 'is-invalid' : 'is-valid'
                "
              />
            </td>
            <td class="text-center">
              <select
                class="form-control form-control-sm"
                v-model="form_tarifa.tipo_add"
                :class="
                  $v.form_tarifa.tipo_add.$invalid ? 'is-invalid' : 'is-valid'
                "
              >
                <option value="">Seleccione...</option>
                <option
                  v-for="tadd in listasForms.tipos_tarifa_add"
                  :key="tadd.numeracion"
                  :value="tadd.numeracion"
                >
                  {{ tadd.descripcion }}
                </option>
              </select>
            </td>
            <td class="text-center">
              <span class="badge badge-success">ACTIVO</span>
            </td>
            <td class="text-right">
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-sm bg-success"
                  v-if="
                    !$v.form_tarifa.$invalid &&
                      $store.getters.can('cs.tarifarios.editTarifasAdicionales')
                  "
                  @click="save()"
                >
                  <i class="fas fa-save"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-sm bg-danger"
                  @click="getFormTarifaAdd(0)"
                >
                  <i class="fas fa-ban"></i>
                </button>
              </div>
            </td>
          </tr>
          <template v-for="(tarifa, indice) in tarAdicionales">
            <tr
              data-widget="expandable-table"
              aria-expanded="false"
              :key="indice"
            >
              <td class="text-center">
                <input
                  type="text"
                  v-if="indice === selectedIndex && flag_form_detalle == 2"
                  class="form-control form-control-sm"
                  v-model="form_tarifa.nombre"
                  :class="
                    $v.form_tarifa.nombre.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
                {{
                  indice != selectedIndex || flag_form_detalle != 2
                    ? tarifa.nombre
                    : ""
                }}
              </td>
              <td class="text-center text-nowrap">
                <input
                  type="date"
                  v-if="indice === selectedIndex && flag_form_detalle == 2"
                  class="form-control form-control-sm"
                  v-model="form_tarifa.fecha_ini"
                  :class="
                    $v.form_tarifa.fecha_ini.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  @input="validaFechas(indice)"
                />
                {{
                  indice != selectedIndex || flag_form_detalle != 2
                    ? tarifa.detalles[0].fecha_ini
                    : ""
                }}
              </td>

              <td class="text-right">
                <input
                  type="number"
                  v-if="indice === selectedIndex && flag_form_detalle == 2"
                  class="form-control form-control-sm"
                  v-model="form_tarifa.valor"
                  :class="
                    $v.form_tarifa.valor.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
                {{
                  indice != selectedIndex || flag_form_detalle != 2
                    ? tarifa.detalles[0].valor
                    : ""
                }}
              </td>
              <td class="text-center text-nowrap">
                <select
                  class="form-control form-control-sm"
                  v-model="form_tarifa.tipo_add"
                  :class="
                    $v.form_tarifa.tipo_add.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  v-if="indice === selectedIndex && flag_form_detalle == 2"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tadd in listasForms.tipos_tarifa_add"
                    :key="tadd.numeracion"
                    :value="tadd.numeracion"
                  >
                    {{ tadd.descripcion }}
                  </option>
                </select>
                {{
                  indice != selectedIndex || flag_form_detalle != 2
                    ? tarifa.nTipoAdd
                    : ""
                }}
              </td>
              <td class="text-center">
                <span
                  class="badge"
                  v-bind:class="[
                    tarifa.estado == 1 ? 'badge-success' : 'badge-danger',
                  ]"
                >
                  {{ tarifa.nEstado }}
                </span>
              </td>
              <td class="text-right">
                <div class="btn-group" v-if="flag_form_detalle == 0">
                  <button
                    type="button"
                    class="btn btn-sm bg-navy"
                    @click="getFormTarifaAdd(2, indice)"
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                </div>

                <div
                  class="btn-group"
                  v-if="indice === selectedIndex && flag_form_detalle == 2"
                >
                  <button
                    type="button"
                    class="btn btn-sm bg-success"
                    v-if="
                      !$v.form_tarifa.$invalid &&
                        $store.getters.can(
                          'cs.tarifarios.editTarifasAdicionales'
                        ) /* &&
                        $parent.$parent.estado_tarifario == 1 */
                    "
                    @click="save()"
                  >
                    <i class="fas fa-save"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm bg-danger"
                    @click="getFormTarifaAdd(0)"
                  >
                    <i class="fas fa-ban"></i>
                  </button>
                </div>
              </td>
            </tr>
            <tr class="expandable-body d-none" :key="indice + 'a'">
              <td colspan="6">
                <table
                  class="table table-bordered table-striped table-hover table-sm m-2"
                >
                  <thead :class="'bg-lightsuccess'">
                    <tr>
                      <th class="text-center">Fecha Inicial</th>
                      <th class="text-center">Fecha Final</th>
                      <th class="text-center">Valor</th>
                      <th class="text-center">Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(det, index) in tarifa.detalles"
                      :key="index"
                      :class="'bg-light'"
                    >
                      <td class="text-center">
                        {{ det.fecha_ini }}
                      </td>
                      <td class="text-center">
                        {{ det.fecha_fin }}
                      </td>
                      <td class="text-center">
                        {{ det.valor }}
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          v-bind:class="[
                            det.estado == 1 ? 'badge-success' : 'badge-danger',
                          ]"
                        >
                          {{ det.nEstado }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CsTarifariosTarifasAdicionales",

  components: {},

  data() {
    return {
      cs_tarifario_id: null,
      cs_tarifa_adicional_id: null,
      cs_tarifa_trabajo_id: null,
      tarifa_estado: null,
      posicion: false,
      tarAdicionales: {},
      modal_title: "",
      listasForms: {
        equipos_types: [],
        tipo_tiempos_tarifas: [],
        clases_tarifas: [],
        estados: [],
        tipos_vehiculos: [],
        tipos_equipos: [],
        tipos_tarifa_add: [],
      },
      form_tarifa: {},
      flag_form_detalle: 0,
      det_tarifa: {},
      form_detalle: {},
      selectedIndex: -1,
    };
  },

  validations: {
    form_tarifa: {
      nombre: {
        required,
      },
      valor: {
        required,
      },
      fecha_ini: {
        required,
      },
      estado: {
        required,
      },
      tipo_add: {
        required,
      },
    },
    form_detalle: {
      valor: {
        required,
      },
      fecha_ini: {
        required,
      },
      /* tipo_add: {
        required,
      }, */
      estado: {
        required,
      },
    },
  },

  methods: {
    async llenaModal(tarifaTrabajo) {
      this.cs_tarifario_id = tarifaTrabajo.cs_tarifario_id;
      this.cs_tarifa_trabajo_id = tarifaTrabajo.id;
      this.flag_form_detalle = 0;
      await this.getTarifasAdicionales();
    },

    async getTarifasAdicionales() {
      this.$parent.$parent.cargando = true;
      let params = {
        cs_tarifario_id: this.cs_tarifario_id,
        cs_tarifa_trabajo_id: this.cs_tarifa_trabajo_id,
      };
      await axios
        .get("/api/cs/tarifarios/tarifas/adicionales/show", {
          params,
        })
        .then((response) => {
          this.tarAdicionales = response.data;
          this.$parent.$parent.cargando = false;
        });
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getTiposTarifasAdd() {
      axios.get("/api/lista/190").then((response) => {
        this.listasForms.tipos_tarifa_add = response.data;
      });
    },

    getFormTarifaAdd(status, index = null) {
      this.flag_form_detalle = status;
      this.posicion = false;
      this.selectedIndex = index;

      switch (status) {
        case 1:
          this.form_tarifa = {
            id: null,
            cs_tarifa_trabajo_id: this.cs_tarifa_trabajo_id,
            cs_tarifario_id: this.cs_tarifario_id,
            cs_tarifa_adicional_id: null,
            cs_det_tarifa_adicional_id: null,
            nombre: null,
            valor: null,
            fecha_ini: null,
            fecha_fin: null,
            tipo_add: null,
            estado: 1,
          };
          break;
        case 2:
          this.form_tarifa = {
            id: this.tarAdicionales[index].id,
            cs_tarifa_trabajo_id: this.tarAdicionales[index]
              .cs_tarifa_trabajo_id,
            cs_tarifario_id: this.tarAdicionales[index].cs_tarifario_id,
            cs_tarifa_adicional_id: this.tarAdicionales[index].detalles[0]
              .cs_tarifa_adicional_id,
            nombre: this.tarAdicionales[index].nombre,
            tipo_add: this.tarAdicionales[index].tipo_add,
            valor: this.tarAdicionales[index].detalles[0].valor,
            fecha_ini: this.tarAdicionales[index].detalles[0].fecha_ini,
            fecha_fin: this.tarAdicionales[index].detalles[0].fecha_fin,
            estado: this.tarAdicionales[index].detalles[0].estado,
          };
          break;
        default:
          break;
      }
    },

    // Funciones para validaciones de los datos
    validaFechas(index) {
      let msg_error = "";
      if (this.form_tarifa.fecha_ini) {
        const fecha_menor = new Date(this.form_tarifa.fecha_ini);
        if (this.tarAdicionales.length > 0) {
          let pos = index == null ? 0 : 0;
          if (this.tarAdicionales[pos].detalles[pos]) {
            console.log(pos);
            let fecha_limite = null;
            if (this.tarAdicionales[pos].detalles[pos].fecha_fin) {
              fecha_limite = new Date(
                this.tarAdicionales[pos].detalles[pos].fecha_fin
              );
            } else {
              fecha_limite = new Date(
                this.tarAdicionales[pos].detalles[pos].fecha_ini
              );
            }
            if (fecha_menor <= fecha_limite) {
              this.form_tarifa.fecha_ini = null;
              msg_error = `La fecha inicio no puede ser menor a la fecha final de la tarifa actual...`;
            }
          }
        }
        if (this.form_tarifa.fecha_ini && this.form_tarifa.fecha_fin) {
          const fecha_mayor = new Date(this.form_tarifa.fecha_fin);
          // Se valida que la fecha inicial sea menor que la fecha final
          if (fecha_menor > fecha_mayor) {
            this.form_tarifa.fecha_fin = null;
            msg_error = `La fecha inicio no puede ser mayor a la fecha final...`;
          }
        }
      }

      if (msg_error) {
        this.$swal({
          icon: "error",
          title: msg_error,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },

    // Funciones para las acciones de los formularios
    save() {
      if (!this.$v.form_tarifa.$invalid) {
        this.$parent.$parent.cargando = true;
        axios({
          method: "POST",
          url: "/api/cs/tarifarios/tarifas/adicionales",
          data: this.form_tarifa,
        })
          .then((response) => {
            this.$parent.$parent.cargando = false;
            if (!response.data.msg_error) {
              this.$parent.getIndex();
              this.getTarifasAdicionales();
              this.flag_form_detalle = 0;
              this.$swal({
                icon: "success",
                title: "El tarifario se guardó exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            } else {
              this.form_tarifa.nombre = null;
              this.$swal({
                icon: "error",
                title: response.data.msg_error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          })
          .catch((e) => {
            this.$parent.$parent.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
  },

  mounted() {
    this.getEstados();
    this.getTiposTarifasAdd();
  },
};
</script>
<style>
.bg-lightsuccess {
  background-color: #d4edda !important;
}
</style>
